import React from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";
import LatticeGrid from "lattice/lib/components/LatticeGrid";
import { GatsbyImage } from "gatsby-plugin-image";
import SEO from "../components/functional/seo";
import DesignStory from "../components/molecules/design-story";
import MediaCarousel from "../components/organisms/media-carousel";
// import CodeBlock from "../components/atoms/code-block";
const CodeBlock = loadable(() => import("../components/atoms/code-block"));

function DesignProductHero({ props }) {
  const designProps = props;
  return (
    <div className="design__content design__content--media">
      <SEO title="Design System - Product Hero" />
      <h2>Product Hero</h2>
      <div className="design__stories">
        <div className="design__story-section">
          <DesignStory
            title="Product Hero"
            component={MediaCarousel}
            subComponent={GatsbyImage}
            subProps={{
              displayName: "Img",
            }}
            type="carousel"
            props={designProps}
            wrapperComponent={LatticeGrid}
            wrapperProps={{
              fullWidth: true,
              innerGrid: true,
              className: "product-hero",
            }}
          />
          <p className="design__text p2">
            The product hero carousel includes custom paging not reflected in
            the code. Below is the function to include in the carousel settings:
          </p>
          <CodeBlock language="jsx">
            {`customPaging: i => {
return (
  <button type="button">
    <Image
      image={images[i].image}
      alt="Product Thumbnail"
      className="product-thumb"
      loading="eager"
    />
  </button>
);
}`}
          </CodeBlock>
        </div>
      </div>
    </div>
  );
}

DesignProductHero.propTypes = {
  props: PropTypes.objectOf(PropTypes.any),
};

DesignProductHero.defaultProps = {
  props: {},
};

export default DesignProductHero;
